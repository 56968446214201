import request from '@/utils/request'

// 获取所有审核人
export function adminUserList() {
    return request({
        url: '/adminUserList',
        method: 'get',
    })
}
// 修改审核人
export function einspectStatus(data) {
    return request({
        url: '/inspectStatusUp',
        method: 'get',
        params: data,
    })
}
// 部门列表 
export function loadDepartmentData() {
    return request({
        url: '/list_department?page=1&size=999',
        method: 'get',
    })
}

// 权限树
export function loadMenu() {
    return request({
        url: '/get_menu_list',
        method: 'get',
    })
}

//注册接口
export function register(data) {
    return request({
        url: '/register',
        method: 'post',
        data
    })
}

// 用户注册---检查账户是否重复
export function getbywhere(data) {
    return request({
        url: '/getbywhere',
        method: 'get',
        params: data,
    })
}

// 用户注册---获取部门列表
export function getUserDepartment(data) {
    return request({
        url: '/api_department',
        method: 'get',
        params: data,
    })
}

// 用户个人中心 -- 获取
export function getuserinfo() {
    return request({
        url: '/getuserinfo',
        method: 'get',
    })
}

// 用户个人中心-- 保存

export function saveInfo(data) {
    return request({
        url: '/saveuinfo',
        method: 'post',
        data
    })
}


// 用户获取量表信息
export function getusermeaanswer() {
    return request({
        url: '/getusermeaanswer?page=0&pagesize=99999',
        method: 'get',
    })
}

// 用户获取问卷
export function getAllQuestionnaires() {
    return request({
        url: '/all_questionnaires_classify',
        method: 'get',
    })
}


// 数据备份接口
export function dataIndex(data) {
    return request({
        url: '/data_index',
        method: 'get',
        params: data
    })
}

// 获取系统参数
export function selectConfig(data) {
    return request({
        url: '/select_config',
        method: 'get',
        params: data
    })
}

// 提交系统参数
export function updateConfig(data) {
    return request({
        url: '/update_config',
        method: 'post',
        data
    })
}


// 获取全局配置参数
export function getListConfig(data) {
    return request({
        url: '/list_config',
        method: 'get',
        params: data
    })
}


// 获取警告信息
export function getwarnings(data) {
    return request({
        url: '/getwarnings',
        method: 'get',
        params: data
    })
}

// 用户注册自动填写
export function getUserAccount(data) {
    return request({
        url: '/account',
        method: 'post',
        data
    })
}

// 获取日志
export function queryLog(data) {
    return request({
        url: '/query_log',
        method: 'get',
        params: data
    })
}
// 定时备份
export function timer(data) {
    return request({
        url: '/timer',
        method: 'get',
    })
}